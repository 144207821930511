<script>
import gql from "graphql-tag";
import { SuawParagraph } from "@/components";
import { resolveComplaintProfile } from "@/screensAdmin/userComplaints/userComplaintsApi.js";
import { deactivateUser, reactivateUser } from "@/screensAdmin/userView/userViewApi.js";

export default {
  name: "UnresolvedProfileComplaints",

  components: {
    SuawParagraph
  },

  data() {
    return {
      error: null,
      usersWithProfileComplaints: []
    };
  },

  apollo: {
    complaints: {
      query: gql`
        query GetProfileComplaints {
          community_complaints_profiles(where: { complaint_profile_resolution_id: { _is_null: true } }, order_by: { created_at: asc }) {
            id
            comment
            created_at
            user {
              id
              display_name
              description_json
              deleted_at
              resolved_complaints_aggregate: complaints_profiles_aggregate(where: { complaint_profile_resolution_id: { _is_null: false } }) {
                aggregate {
                  count
                }
              }
              resolved_complaints: complaints_profiles(where: { complaint_profile_resolution_id: { _is_null: false } }) {
                id
                comment
                created_at
                resolution: complaints_profiles_resolution {
                  comment
                  created_at
                }
                reporting_user {
                  id
                  display_name
                }
              }
              post_complaints_aggregate: complaints_posts_aggregate {
                aggregate {
                  count
                }
              }
              avatar_complaints_aggregate: complaints_avatars_aggregate {
                aggregate {
                  count
                }
              }
            }
            reporting_user {
              id
              display_name
            }
          }
        }
      `,
      update(data) {
        // Group profile complaints by user
        const profileUserMap = new Map();
        const profileComplaints = data?.community_complaints_profiles || [];

        profileComplaints.forEach(complaint => {
          const user = complaint.user;
          if (!profileUserMap.has(user.id)) {
            profileUserMap.set(user.id, {
              ...user,
              complaints_profiles: [],
              resolutionComment: "",
              isSubmitting: false,
              showResolved: false
            });
          }
          profileUserMap.get(user.id).complaints_profiles.push({
            id: complaint.id,
            comment: complaint.comment,
            created_at: complaint.created_at,
            reporting_user: complaint.reporting_user
          });
        });

        this.usersWithProfileComplaints = Array.from(profileUserMap.values());
      },
      error(error) {
        this.error = "Error loading profile complaints: " + error.message;
      }
    }
  },

  methods: {
    getTotalComplaints(user) {
      return (
        user.complaints_profiles.length + // Current unresolved profile complaints
        user.resolved_complaints_aggregate.aggregate.count + // Resolved profile complaints
        user.post_complaints_aggregate.aggregate.count + // All post complaints
        user.avatar_complaints_aggregate.aggregate.count // All avatar complaints
      );
    },

    toggleResolvedComplaints(user) {
      user.showResolved = !user.showResolved;
    },

    openUserProfile(userId) {
      const route = this.$router.resolve({
        name: "UserView",
        params: { userId }
      });
      window.open(route.href, "_blank");
    },

    async resolveProfileComplaints(user, deactivate) {
      if (!user.resolutionComment) return;

      user.isSubmitting = true;

      // Get all complaint IDs for this user
      const complaintIds = user.complaints_profiles.map(c => c.id);

      // Resolve all complaints
      const resolveResult = await resolveComplaintProfile(complaintIds, user.resolutionComment);
      if (!resolveResult.success) {
        this.$root.$emit("universal-error-message", resolveResult.error);
      }

      // If requested, deactivate/reactivate the profile
      if (deactivate) {
        const deactivateResult = await deactivateUser(user.id);
        if (!deactivateResult.success) {
          this.$root.$emit("universal-error-message", deactivateResult.error);
        }
      } else {
        const reactivateResult = await reactivateUser(user.id);
        if (!reactivateResult.success) {
          this.$root.$emit("universal-error-message", reactivateResult.error);
        }
      }

      // Remove the resolved user from the list
      this.usersWithProfileComplaints = this.usersWithProfileComplaints.filter(u => u.id !== user.id);
      user.isSubmitting = false;
    }
  }
};
</script>

<template>
  <div>
    <h1>Unresolved Profile Complaints</h1>
    <div v-if="$apollo.loading" class="loading">
      Loading...
    </div>

    <div v-else-if="error" class="error">
      {{ error }}
    </div>

    <div v-else-if="usersWithProfileComplaints.length > 0" class="complaints-grid">
      <div v-for="user in usersWithProfileComplaints" :key="user.id" class="complaint-card">
        <div class="profile-content">
          <SuawParagraph :text="user.description_json" />
        </div>

        <div class="user-info">
          <a href="#" class="view-profile-link" @click.prevent="openUserProfile(user.id)">
            <h3>{{ user.display_name }}</h3>
          </a>
          <div class="stat-badge">
            <span class="stat-count">{{ getTotalComplaints(user) }}</span>
            <span class="stat-label">Total Complaints</span>
          </div>
        </div>

        <div class="resolution-form">
          <div class="form-group">
            <label for="comment">Resolution Comment</label>
            <textarea :id="'comment-' + user.id" v-model="user.resolutionComment" class="form-control" required placeholder="Enter resolution comment..."></textarea>
          </div>
          <div class="resolution-actions">
            <button class="btn btn-success" :disabled="!user.resolutionComment || user.isSubmitting" @click="resolveProfileComplaints(user, false)">
              {{ user.deleted_at !== null ? "Reactivate Profile" : "Keep Profile" }}
            </button>
            <button class="btn btn-danger" :disabled="!user.resolutionComment || user.isSubmitting" @click="resolveProfileComplaints(user, true)">
              Deactivate Profile
            </button>
          </div>
        </div>

        <div class="complaints-section">
          <h4>Unresolved ({{ user.complaints_profiles.length }})</h4>
          <div v-for="complaint in user.complaints_profiles" :key="complaint.id" class="complaint">
            <p class="complaint-text">{{ complaint.comment }}</p>
            <div class="complaint-meta">
              <span>
                Reported by:
                <a href="#" class="view-profile-link" @click.prevent="openUserProfile(complaint.reporting_user.id)">
                  {{ complaint.reporting_user.display_name }}
                </a>
              </span>
              <span>{{ new Date(complaint.created_at).toLocaleDateString() }}</span>
            </div>
          </div>
        </div>

        <div class="complaints-section">
          <div class="resolved-header" @click="toggleResolvedComplaints(user)">
            <h4>Resolved ({{ user.resolved_complaints.length }})</h4>
            <span class="toggle-icon">{{ user.showResolved ? "▼" : "▶" }}</span>
          </div>
          <div v-if="user.showResolved" class="resolved-complaints">
            <div v-for="complaint in user.resolved_complaints" :key="complaint.id" class="complaint resolved">
              <p class="complaint-text">{{ complaint.comment }}</p>
              <p class="resolution-text">Resolution: {{ complaint.resolution.comment }}</p>
              <div class="complaint-meta">
                <span>
                  Reported by:
                  <a href="#" class="view-profile-link" @click.prevent="openUserProfile(complaint.reporting_user.id)">
                    {{ complaint.reporting_user.display_name }}
                  </a>
                </span>
                <span>{{ new Date(complaint.created_at).toLocaleDateString() }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no-complaints">
      No unresolved profile complaints found.
    </div>
  </div>
</template>

<style scoped>
.complaints-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.complaint-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background: white;
}

.profile-content {
  margin-bottom: 15px;
}

.user-info {
  text-align: center;
  margin-bottom: 15px;
}

.user-info h3 {
  margin: 0 0 10px 0;
}

.stat-badge {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px auto;
  min-width: 100px;
}

.stat-count {
  font-size: 1.2em;
  font-weight: bold;
  color: #495057;
}

.stat-label {
  font-size: 0.8em;
  color: #6c757d;
}

.view-profile-link {
  color: #007bff;
  text-decoration: none;
  display: inline-block;
  margin-top: 8px;
}

.view-profile-link:hover {
  text-decoration: underline;
}

.complaints-section {
  border-top: 1px solid #eee;
  padding-top: 15px;
  margin-bottom: 15px;
}

.complaints-section h4 {
  margin: 0 0 10px 0;
}

.resolved-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.resolved-header:hover {
  background-color: #f8f9fa;
}

.toggle-icon {
  color: #6c757d;
}

.complaint {
  background: #f8f9fa;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.complaint.resolved {
  background: #e9ecef;
}

.complaint:last-child {
  margin-bottom: 0;
}

.complaint-text {
  margin: 0 0 10px 0;
}

.resolution-text {
  margin: 5px 0;
  color: #28a745;
  font-style: italic;
}

.complaint-meta {
  font-size: 0.9em;
  color: #666;
  display: flex;
  justify-content: space-between;
}

.no-complaints {
  text-align: center;
  padding: 20px;
  color: #666;
  font-style: italic;
}

.loading,
.error {
  text-align: center;
  padding: 20px;
}

.error {
  color: #dc3545;
}

/* Resolution Form Styles */
.resolution-form {
  border-top: 1px solid #eee;
  padding-top: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 80px;
  resize: vertical;
}

.resolution-actions {
  display: flex;
  gap: 10px;
}

.btn {
  flex: 1;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: opacity 0.2s;
}

.btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn-success {
  background-color: #28a745;
  color: white;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
}
</style>
