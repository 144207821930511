<script>
import gql from "graphql-tag";
import { SuawParagraph } from "@/components";
import { resolveComplaintPost } from "@/screensAdmin/userComplaints/userComplaintsApi.js";
import * as DiscussionApi from "@/features/discussions/api";

export default {
  name: "UnresolvedPostComplaints",

  components: {
    SuawParagraph
  },

  data() {
    return {
      error: null,
      postsWithComplaints: []
    };
  },

  apollo: {
    complaints: {
      query: gql`
        query GetPostComplaints {
          community_complaints_posts(where: { complaint_post_resolution_id: { _is_null: true } }, order_by: { created_at: asc }) {
            id
            comment
            created_at
            post {
              id
              body_json
              posted_at
              deleted_at
              resolved_complaints: complaints_posts(where: { complaint_post_resolution_id: { _is_null: false } }) {
                id
                comment
                created_at
                resolution: complaints_posts_resolution {
                  comment
                  created_at
                }
                reporting_user {
                  id
                  display_name
                }
              }
            }
            user {
              id
              display_name
              initials
              avatar_url
              avatar_color
              post_complaints_aggregate: complaints_posts_aggregate {
                aggregate {
                  count
                }
              }
              profile_complaints_aggregate: complaints_profiles_aggregate {
                aggregate {
                  count
                }
              }
              avatar_complaints_aggregate: complaints_avatars_aggregate {
                aggregate {
                  count
                }
              }
            }
            reporting_user {
              id
              display_name
            }
          }
        }
      `,
      update(data) {
        // Group post complaints by post
        const postMap = new Map();
        const postComplaints = data?.community_complaints_posts || [];

        postComplaints.forEach(complaint => {
          const post = complaint.post;
          if (!postMap.has(post.id)) {
            postMap.set(post.id, {
              ...post,
              complaints_posts: [],
              firstComplaint: complaint,
              resolutionComment: "",
              isSubmitting: false,
              showResolved: false
            });
          }
          postMap.get(post.id).complaints_posts.push({
            id: complaint.id,
            comment: complaint.comment,
            created_at: complaint.created_at,
            reporting_user: complaint.reporting_user,
            user: complaint.user
          });
        });

        this.postsWithComplaints = Array.from(postMap.values());
      },
      error(error) {
        this.error = "Error loading post complaints: " + error.message;
      }
    }
  },

  methods: {
    getTotalComplaints(user) {
      return (
        user.post_complaints_aggregate.aggregate.count + // All post complaints
        user.profile_complaints_aggregate.aggregate.count + // All profile complaints
        user.avatar_complaints_aggregate.aggregate.count // All avatar complaints
      );
    },

    formatDateAt(dstr) {
      const d = new Date(dstr);
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
    },

    toggleResolvedComplaints(post) {
      post.showResolved = !post.showResolved;
    },

    openUserProfile(userId) {
      const route = this.$router.resolve({
        name: "UserView",
        params: { userId }
      });
      window.open(route.href, "_blank");
    },

    async resolvePostComplaints(post, deletePost) {
      if (!post.resolutionComment) return;

      post.isSubmitting = true;

      // Get complaint IDs for this post
      const complaintIds = post.complaints_posts.map(c => c.id);

      // Resolve all complaints for this post
      const resolveResult = await resolveComplaintPost(complaintIds, post.resolutionComment);
      if (!resolveResult.success) {
        this.$root.$emit("universal-error-message", resolveResult.error);
      }

      // If requested, delete the post
      if (deletePost) {
        const removeResult = await DiscussionApi.removePost(post.id);
        if (!removeResult.success) {
          this.$root.$emit("universal-error-message", removeResult.error);
        }
      } else {
        const unremoveResult = await DiscussionApi.unremovePost(post.id);
        if (!unremoveResult.success) {
          this.$root.$emit("universal-error-message", unremoveResult.error);
        }
      }

      // Remove the resolved post from the list
      this.postsWithComplaints = this.postsWithComplaints.filter(p => p.id !== post.id);
      post.isSubmitting = false;
    }
  }
};
</script>

<template>
  <div>
    <h1>Unresolved Post Complaints</h1>
    <div v-if="$apollo.loading" class="loading">
      Loading...
    </div>

    <div v-else-if="error" class="error">
      {{ error }}
    </div>

    <div v-else-if="postsWithComplaints.length > 0" class="complaints-grid">
      <div v-for="post in postsWithComplaints" :key="post.id" class="complaint-card">
        <div class="post-content">
          <SuawParagraph :text="post.body_json" />
          <p class="post-date">Posted: {{ formatDateAt(post.posted_at) }}</p>
        </div>

        <div class="user-info">
          <a href="#" class="view-profile-link" @click.prevent="openUserProfile(post.firstComplaint.user.id)">
            <h3>{{ post.firstComplaint.user.display_name }}</h3>
          </a>
          <div class="stat-badge">
            <span class="stat-count">{{ getTotalComplaints(post.firstComplaint.user) }}</span>
            <span class="stat-label">Total Complaints</span>
          </div>
        </div>

        <div class="resolution-form">
          <div class="form-group">
            <label for="comment">Resolution Comment</label>
            <textarea v-model="post.resolutionComment" :id="'comment-' + post.id" class="form-control" required placeholder="Enter resolution comment..."></textarea>
          </div>
          <div class="resolution-actions">
            <button class="btn btn-success" @click="resolvePostComplaints(post, false)" :disabled="!post.resolutionComment || post.isSubmitting">
              Allow Post
            </button>
            <button class="btn btn-danger" @click="resolvePostComplaints(post, true)" :disabled="!post.resolutionComment || post.isSubmitting">
              Delete Post
            </button>
          </div>
        </div>

        <div class="complaints-section">
          <h4>Unresolved ({{ post.complaints_posts.length }})</h4>
          <div v-for="complaint in post.complaints_posts" :key="complaint.id" class="complaint">
            <p class="complaint-text">{{ complaint.comment }}</p>
            <div class="complaint-meta">
              <span>
                Reported by:
                <a href="#" class="view-profile-link" @click.prevent="openUserProfile(complaint.reporting_user.id)">
                  {{ complaint.reporting_user.display_name }}
                </a>
              </span>
              <span>{{ new Date(complaint.created_at).toLocaleDateString() }}</span>
            </div>
          </div>
        </div>

        <div class="complaints-section">
          <div class="resolved-header" @click="toggleResolvedComplaints(post)">
            <h4>Resolved ({{ post.resolved_complaints.length }})</h4>
            <span class="toggle-icon">{{ post.showResolved ? "▼" : "▶" }}</span>
          </div>
          <div v-if="post.showResolved" class="resolved-complaints">
            <div v-for="complaint in post.resolved_complaints" :key="complaint.id" class="complaint resolved">
              <p class="complaint-text">{{ complaint.comment }}</p>
              <p class="resolution-text">Resolution: {{ complaint.resolution.comment }}</p>
              <div class="complaint-meta">
                <span>
                  Reported by:
                  <a href="#" class="view-profile-link" @click.prevent="openUserProfile(complaint.reporting_user.id)">
                    {{ complaint.reporting_user.display_name }}
                  </a>
                </span>
                <span>{{ new Date(complaint.created_at).toLocaleDateString() }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no-complaints">
      No unresolved post complaints found.
    </div>
  </div>
</template>

<style scoped>
.complaints-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.complaint-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background: white;
}

.post-content {
  margin-bottom: 12px;
}

.post-date {
  color: #6c757d;
  font-size: 0.85em;
  margin-top: 8px;
  font-style: italic;
}

.user-info {
  text-align: center;
  margin-bottom: 15px;
}

.user-info h3 {
  margin: 0 0 10px 0;
}

.stat-badge {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px auto;
  min-width: 100px;
}

.stat-count {
  font-size: 1.2em;
  font-weight: bold;
  color: #495057;
}

.stat-label {
  font-size: 0.8em;
  color: #6c757d;
}

.avatar-wrapper-small {
  width: 24px;
  height: 24px;
}

.view-profile-link {
  color: #007bff;
  text-decoration: none;
  display: inline-block;
  margin-top: 8px;
}

.view-profile-link:hover {
  text-decoration: underline;
}

.complaints-section {
  border-top: 1px solid #eee;
  padding-top: 15px;
  margin-bottom: 15px;
}

.complaints-section h4 {
  margin: 0 0 10px 0;
}

.resolved-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.resolved-header:hover {
  background-color: #f8f9fa;
}

.toggle-icon {
  color: #6c757d;
}

.complaint {
  background: #f8f9fa;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.complaint.resolved {
  background: #e9ecef;
}

.complaint:last-child {
  margin-bottom: 0;
}

.complaint-text {
  margin: 0 0 10px 0;
}

.resolution-text {
  margin: 5px 0;
  color: #28a745;
  font-style: italic;
}

.complaint-meta {
  font-size: 0.9em;
  color: #666;
  display: flex;
  justify-content: space-between;
}

.no-complaints {
  text-align: center;
  padding: 20px;
  color: #666;
  font-style: italic;
}

.loading,
.error {
  text-align: center;
  padding: 20px;
}

.error {
  color: #dc3545;
}

/* Resolution Form Styles */
.resolution-form {
  border-top: 1px solid #eee;
  padding-top: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 80px;
  resize: vertical;
}

.resolution-actions {
  display: flex;
  gap: 10px;
}

.btn {
  flex: 1;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: opacity 0.2s;
}

.btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn-success {
  background-color: #28a745;
  color: white;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
}
</style>
