<script>
import UnresolvedAvatarComplaints from "./components/UnresolvedAvatarComplaints.vue";
import UnresolvedPostComplaints from "./components/UnresolvedPostComplaints.vue";
import UnresolvedProfileComplaints from "./components/UnresolvedProfileComplaints.vue";

export default {
  name: "UnresolvedComplaints",

  components: {
    UnresolvedAvatarComplaints,
    UnresolvedPostComplaints,
    UnresolvedProfileComplaints
  }
};
</script>

<template>
  <div class="unresolved-complaints">
    <UnresolvedAvatarComplaints />
    <br />
    <UnresolvedPostComplaints />
    <br />
    <UnresolvedProfileComplaints />
  </div>
</template>

<style scoped>
.unresolved-complaints {
  padding: 20px;
}
</style>
