<script>
import gql from "graphql-tag";
import { SuawAvatarView } from "@/components";
import { resolveComplaintAvatar, hideAvatar, unhideAvatar } from "@/screensAdmin/userView/userViewApi.js";

export default {
  name: "UnresolvedAvatarComplaints",

  components: {
    SuawAvatarView
  },

  data() {
    return {
      error: null,
      usersWithAvatarComplaints: []
    };
  },

  apollo: {
    complaints: {
      query: gql`
        query GetAvatarComplaints {
          community_complaints_avatars(where: { complaint_avatar_resolution_id: { _is_null: true } }, order_by: { created_at: asc }) {
            id
            comment
            created_at
            user {
              id
              display_name
              initials
              avatar_url
              avatar_color
              is_avatar_hidden
              resolved_complaints_aggregate: complaints_avatars_aggregate(where: { complaint_avatar_resolution_id: { _is_null: false } }) {
                aggregate {
                  count
                }
              }
              resolved_complaints: complaints_avatars(where: { complaint_avatar_resolution_id: { _is_null: false } }) {
                id
                comment
                created_at
                resolution: complaints_avatars_resolution {
                  comment
                  created_at
                }
                reporting_user {
                  id
                  display_name
                }
              }
              post_complaints_aggregate: complaints_posts_aggregate {
                aggregate {
                  count
                }
              }
              profile_complaints_aggregate: complaints_profiles_aggregate {
                aggregate {
                  count
                }
              }
            }
            reporting_user {
              id
              display_name
            }
          }
        }
      `,
      update(data) {
        // Group avatar complaints by user
        const avatarUserMap = new Map();
        const avatarComplaints = data?.community_complaints_avatars || [];

        avatarComplaints.forEach(complaint => {
          const user = complaint.user;
          if (!avatarUserMap.has(user.id)) {
            avatarUserMap.set(user.id, {
              ...user,
              complaints_avatars: [],
              resolutionComment: "",
              isSubmitting: false,
              showResolved: false
            });
          }
          avatarUserMap.get(user.id).complaints_avatars.push({
            id: complaint.id,
            comment: complaint.comment,
            created_at: complaint.created_at,
            reporting_user: complaint.reporting_user
          });
        });

        this.usersWithAvatarComplaints = Array.from(avatarUserMap.values());
      },
      error(error) {
        this.error = "Error loading avatar complaints: " + error.message;
      }
    }
  },

  methods: {
    getTotalComplaints(user) {
      return (
        user.complaints_avatars.length + // Current unresolved avatar complaints
        user.resolved_complaints_aggregate.aggregate.count + // Resolved avatar complaints
        user.post_complaints_aggregate.aggregate.count + // All post complaints
        user.profile_complaints_aggregate.aggregate.count // All profile complaints
      );
    },

    toggleResolvedComplaints(user) {
      user.showResolved = !user.showResolved;
    },

    openUserProfile(userId) {
      const route = this.$router.resolve({
        name: "UserView",
        params: { userId }
      });
      window.open(route.href, "_blank");
    },

    async resolveAvatarComplaints(user, hide) {
      if (!user.resolutionComment) return;

      user.isSubmitting = true;

      // Get all complaint IDs for this user
      const complaintIds = user.complaints_avatars.map(c => c.id);

      // Resolve all complaints
      const resolveResult = await resolveComplaintAvatar(complaintIds, user.resolutionComment);
      if (!resolveResult.success) {
        this.$root.$emit("universal-error-message", resolveResult.error);
      }

      // If legit complaint, hide the user's avatar
      if (hide) {
        const hideResult = await hideAvatar(user.id);
        if (!hideResult.success) {
          this.$root.$emit("universal-error-message", hideResult.error);
        }
      } else {
        const unhideResult = await unhideAvatar(user.id);
        if (!unhideResult.success) {
          this.$root.$emit("universal-error-message", unhideResult.error);
        }
      }

      // Remove the resolved user from the list
      this.usersWithAvatarComplaints = this.usersWithAvatarComplaints.filter(u => u.id !== user.id);
      user.isSubmitting = false;
    }
  }
};
</script>

<template>
  <div>
    <h1>Unresolved Avatar Complaints</h1>
    <div v-if="$apollo.loading" class="loading">
      Loading...
    </div>

    <div v-else-if="error" class="error">
      {{ error }}
    </div>

    <div v-else-if="usersWithAvatarComplaints.length > 0" class="complaints-grid">
      <div v-for="user in usersWithAvatarComplaints" :key="user.id" class="complaint-card">
        <div class="avatar-wrapper">
          <SuawAvatarView v-if="user.avatar_url" size="huge" :display-name="user.display_name" :initials="user.initials" :url="user.avatar_url" />
          <SuawAvatarView size="huge" :display-name="user.display_name" :initials="user.initials" :color="user.avatar_color" />
        </div>

        <div class="user-info">
          <a href="#" class="view-profile-link" @click.prevent="openUserProfile(user.id)">
            <h3>{{ user.display_name }}</h3>
          </a>
          <div class="stat-badge">
            <span class="stat-count">{{ getTotalComplaints(user) }}</span>
            <span class="stat-label">Total Complaints</span>
          </div>
        </div>

        <div class="resolution-form">
          <div class="form-group">
            <label for="comment">Resolution Comment</label>
            <textarea v-model="user.resolutionComment" :id="'comment-' + user.id" class="form-control" required placeholder="Enter resolution comment..."></textarea>
          </div>
          <div class="resolution-actions">
            <button class="btn btn-success" @click="resolveAvatarComplaints(user, false)" :disabled="!user.resolutionComment || user.isSubmitting">
              Show Avatar
            </button>
            <button class="btn btn-danger" @click="resolveAvatarComplaints(user, true)" :disabled="!user.resolutionComment || user.isSubmitting">
              Hide Avatar
            </button>
          </div>
        </div>

        <div class="complaints-section">
          <h4>Unresolved ({{ user.complaints_avatars.length }})</h4>
          <div v-for="complaint in user.complaints_avatars" :key="complaint.id" class="complaint">
            <p class="complaint-text">{{ complaint.comment }}</p>
            <div class="complaint-meta">
              <span>
                Reported by:
                <a href="#" class="view-profile-link" @click.prevent="openUserProfile(complaint.reporting_user.id)">
                  {{ complaint.reporting_user.display_name }}
                </a>
              </span>
              <span>{{ new Date(complaint.created_at).toLocaleDateString() }}</span>
            </div>
          </div>
        </div>

        <div class="complaints-section">
          <div class="resolved-header" @click="toggleResolvedComplaints(user)">
            <h4>Resolved ({{ user.resolved_complaints.length }})</h4>
            <span class="toggle-icon">{{ user.showResolved ? "▼" : "▶" }}</span>
          </div>
          <div v-if="user.showResolved" class="resolved-complaints">
            <div v-for="complaint in user.resolved_complaints" :key="complaint.id" class="complaint resolved">
              <p class="complaint-text">{{ complaint.comment }}</p>
              <p class="resolution-text">Resolution: {{ complaint.resolution.comment }}</p>
              <div class="complaint-meta">
                <span>
                  Reported by:
                  <a href="#" class="view-profile-link" @click.prevent="openUserProfile(complaint.reporting_user.id)">
                    {{ complaint.reporting_user.display_name }}
                  </a>
                </span>
                <span>{{ new Date(complaint.created_at).toLocaleDateString() }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no-complaints">
      No unresolved avatar complaints found.
    </div>
  </div>
</template>

<style scoped>
.complaints-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.complaint-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background: white;
}

.avatar-wrapper {
  text-align: center;
  margin-bottom: 15px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-info {
  text-align: center;
  margin-bottom: 15px;
}

.user-info h3 {
  margin: 0 0 10px 0;
}

.stat-badge {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px auto;
  min-width: 100px;
}

.stat-count {
  font-size: 1.2em;
  font-weight: bold;
  color: #495057;
}

.stat-label {
  font-size: 0.8em;
  color: #6c757d;
}

.view-profile-link {
  color: #007bff;
  text-decoration: none;
  display: inline-block;
  margin-top: 8px;
}

.view-profile-link:hover {
  text-decoration: underline;
}

.complaints-section {
  border-top: 1px solid #eee;
  padding-top: 15px;
  margin-bottom: 15px;
}

.complaints-section h4 {
  margin: 0 0 10px 0;
}

.resolved-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.resolved-header:hover {
  background-color: #f8f9fa;
}

.toggle-icon {
  color: #6c757d;
}

.complaint {
  background: #f8f9fa;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.complaint.resolved {
  background: #e9ecef;
}

.complaint:last-child {
  margin-bottom: 0;
}

.complaint-text {
  margin: 0 0 10px 0;
}

.resolution-text {
  margin: 5px 0;
  color: #28a745;
  font-style: italic;
}

.complaint-meta {
  font-size: 0.9em;
  color: #666;
  display: flex;
  justify-content: space-between;
}

.no-complaints {
  text-align: center;
  padding: 20px;
  color: #666;
  font-style: italic;
}

.loading,
.error {
  text-align: center;
  padding: 20px;
}

.error {
  color: #dc3545;
}

/* Resolution Form Styles */
.resolution-form {
  border-top: 1px solid #eee;
  padding-top: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 80px;
  resize: vertical;
}

.resolution-actions {
  display: flex;
  gap: 10px;
}

.btn {
  flex: 1;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: opacity 0.2s;
}

.btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn-success {
  background-color: #28a745;
  color: white;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
}
</style>
